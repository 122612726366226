body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x:hidden;
  box-sizing: border-box;
  height:100%;
  width:100%;
  transform: translateZ(0px);
  
}
/* @media(max-width:590px){
  body{
   overflow-y: scroll;
  }
} */

/* @media(max-width:590px){
  html{
   overflow: hidden;
  }
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x:hidden;
  font-size: 62.5%;
  height:100%;
  width:100%;
  
}

/* margin is not part of the box model */

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar {
  display: none;
}

/* * {
  font-size: 1.6rem;
} */

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

* {
  -webkit-overflow-scrolling: touch;
}

img {
  width: 100%;
  height: auto;
}

a {
  color: none;
  text-decoration: none;
}

a:link{
  color: #0080ffc9;
 
}

a:hover {
  text-decoration: none;
}

input:focus,
button:focus {
  outline: none;
}

input,
button {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid lightgray;
}

.App{
  height:100%;
  width:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

.hidden{
  display:none;
}

strong{
  color:#80808082;
}


